// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "w_tl d_gS d_cw d_dv";
export var quoteParagraphCenter = "w_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "w_gV d_gV d_cw d_dx";
export var quoteRowLeft = "w_tm d_bG";
export var quoteRowCenter = "w_tn d_bD";
export var quoteRowRight = "w_tp d_bH";
export var quoteWrapper = "w_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "w_gR d_gR";
export var quoteExceptionSmall = "w_st s_st";
export var quoteExceptionNormal = "w_sv s_sv";
export var quoteExceptionLarge = "w_sw s_sw";
export var quoteAuthorExceptionSmall = "w_sx s_sx";
export var quoteAuthorExceptionNormal = "w_sy s_sy";
export var quoteAuthorExceptionLarge = "w_sz s_sz";