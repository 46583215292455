// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "g_mL";
export var warningBackgrounddark = "g_mM";
export var warningBackgroundcustom = "g_mN";
export var bottom = "g_gH";
export var ribbon = "g_mP";
export var modal = "g_mQ";
export var bottomRight = "g_mR";
export var btnWrapper = "g_d2 d_bD d_bP d_bJ";
export var contentWrapper = "g_mS";
export var container = "g_mT";
export var titleWrapper = "g_mV d_bD d_bP d_dw";
export var elementWrapper = "g_mW d_bD d_bP d_dw";
export var cookieButtons = "g_mX d_bz d_bD d_bP d_bJ";
export var btn = "g_mY d_bz d_bD d_bP";
export var link = "g_mZ";
export var decline = "g_m0 g_mY d_bz d_bD d_bP";
export var hoverStyle = "g_m1";
export var zoom = "g_m2";
export var row = "g_m3";