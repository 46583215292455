// extracted by mini-css-extract-plugin
export var lbContainerOuter = "B_vl";
export var lbContainerInner = "B_vm";
export var movingForwards = "B_vn";
export var movingForwardsOther = "B_vp";
export var movingBackwards = "B_vq";
export var movingBackwardsOther = "B_vr";
export var lbImage = "B_vs";
export var lbOtherImage = "B_vt";
export var prevButton = "B_vv";
export var nextButton = "B_vw";
export var closing = "B_vx";
export var appear = "B_vy";