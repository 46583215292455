// extracted by mini-css-extract-plugin
export var noPadding = "x_fj d_fj";
export var videoFrameWrapper = "x_tq d_kS d_b5";
export var videoIframeStyle = "x_pS d_d5 d_w d_H d_by d_b1 d_R";
export var videoLayoutRight = "x_tr d_kR d_bK";
export var videoInnerWrapper = "x_ts d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5";
export var videoText = "x_tt d_dw d_w";
export var videoInnerInnerWrapper = "x_tv d_bz d_bD d_bJ";
export var btnWrapper = "x_d2 d_w d_bz d_bD d_cj";
export var btn = "x_mY d_b1 d_bz d_bD d_bP d_cH d_b6 d_dB d_n s_sp s_r7";
export var icon = "x_p9 d_cC";
export var textLeft = "x_dv";
export var textCenter = "x_dw";
export var textRight = "x_dx";