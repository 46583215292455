// extracted by mini-css-extract-plugin
export var alignLeft = "r_qj d_fp d_bG d_dv";
export var alignCenter = "r_bP d_fq d_bD d_dw";
export var alignRight = "r_qk d_fr d_bH d_dx";
export var leftWrapper = "r_rp d_bz d_bD d_bJ";
export var wrapper = "r_rq d_w d_cc";
export var imgWrapper = "r_rr";
export var caption = "r_rs";
export var text = "r_qc d_cs";
export var instagramGuttersImage = "r_g2 d_g2 d_cv d_K d_cv";
export var instagramNoGuttersImage = "r_g1 d_g1 d_cD d_K d_cD";
export var instagramContainerFull = "r_rt d_dT d_w";
export var instagramContainer = "r_rv d_dW";
export var content = "r_rw d_cc";
export var errorContainer = "r_rx";
export var instagramImg = "r_ry d_w d_H d_dY d_bd d_0 d_8 d_7 d_4 d_9 d_bR";
export var instagramImageWrapper = "r_rz d_fg d_Z";
export var instagramBtnWrapper = "r_g9 d_g9 d_0 d_bC d_bP d_bX d_dw d_bq";
export var instagramBtnWrapperLeft = "r_rB d_d2 d_d1 d_w d_bz d_bG d_c7";
export var instagramBtnWrapperCenter = "r_rC d_d3 d_d1 d_w d_bz d_bD d_c7";
export var instagramBtnWrapperRight = "r_rD d_d4 d_d1 d_w d_bz d_bH d_c7";