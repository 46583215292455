// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_q5 d_gv d_cs";
export var heroHeaderCenter = "q_gw d_gw d_cs d_dw";
export var heroHeaderRight = "q_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "q_q6 d_gr d_cw";
export var heroParagraphCenter = "q_gs d_gs d_cw d_dw";
export var heroParagraphRight = "q_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "q_q7 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "q_q8 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "q_q9 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "q_rb d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "q_rc d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "q_rd s_rd";
export var heroExceptionNormal = "q_rf s_rf";
export var heroExceptionLarge = "q_rg s_rg";
export var Title1Small = "q_rh s_rh s_rF s_rG";
export var Title1Normal = "q_rj s_rj s_rF s_rH";
export var Title1Large = "q_rk s_rk s_rF s_rJ";
export var BodySmall = "q_rl s_rl s_rF s_rY";
export var BodyNormal = "q_rm s_rm s_rF s_rZ";
export var BodyLarge = "q_rn s_rn s_rF s_r0";